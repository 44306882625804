import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@react-three/drei';
import { useLoader,useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three';

function RivetAnimation() {
  const [displayedText1, setDisplayedText1] = useState(''); 
  const [displayedText2, setDisplayedText2] = useState(''); 
  const [currentChar, setCurrentChar] = useState('');       
  const targetText1 = 'rivetspace';                         
  const targetText2 = '"Participate in Life"';              
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';           
  const animationSpeed = 1;                              

  const currentIndex1 = useRef(0); 
  const currentIndex2 = useRef(0); 
  const imageMesh = useRef();

  useEffect(() => {
    const startAnimation = () => {
      const interval = setInterval(() => {

        if (currentIndex1.current >= targetText1.length && currentIndex2.current >= targetText2.length) {
          clearInterval(interval); 
          setCurrentChar('');
          return;
        }

        let targetChar; 

        if (currentIndex1.current < targetText1.length) {
          targetChar = targetText1[currentIndex1.current];
        } else {
          targetChar = targetText2[currentIndex2.current]; 
        }

        if (!alphabet.includes(targetChar)) {
          if (currentIndex1.current < targetText1.length) {
            setDisplayedText1(prev => prev + targetChar);
            currentIndex1.current++;
          } else {
            setDisplayedText2(prev => prev + targetChar);
            currentIndex2.current++;
          }
          return;
        }
        
        const randomChar = alphabet[Math.floor(Math.random() * alphabet.length)];
        setCurrentChar(randomChar); 

        if (randomChar === targetChar) {
          if (currentIndex1.current < targetText1.length) {
            setDisplayedText1(prev => prev + targetChar);
            currentIndex1.current++; 
          } else {
            setDisplayedText2(prev => prev + targetChar); 
            currentIndex2.current++; 
          }
        }
      }, animationSpeed); 
    };

    startAnimation();

    return () => clearInterval(); 
  }, []);
  const texture = useLoader(TextureLoader, '/rswhite.png');

  useFrame(() => {
    if (imageMesh.current) {
      imageMesh.current.rotation.y += 0.01;
    }
  });

  const handleClick = () => {
    window.open('https://instagram.com/yusufipsum', '_blank');
  };

  const handleRs = () => {
    window.open('https://instagram.com/rivetspace', '_blank');
  };
  return (
    <>
      <Text
        textAlign="center"
        color="white"
        fontSize={1} 
        position={[0, 5, -3]}
      >
        {displayedText1}
      </Text>
      <Text
        textAlign="center"
        color="white"
        fontSize={0.3}
        position={[0, 4, -3]}
      >
        {displayedText2}
      </Text>

      {currentChar && (
        <Text
          textAlign="center"
          color="gray"
          fontSize={currentIndex1.current < targetText1.length ? 1 : 0.3}
          position={[
            currentIndex1.current < targetText1.length ? currentIndex1.current*.25 : currentIndex2.current*.075,
            currentIndex1.current < targetText1.length ? 5 : 4,
            currentIndex1.current < targetText1.length ? -3 : -3
          ]}
        >
          {currentChar}
        </Text>
      )}
       <mesh onClick={handleRs} onPointerOver={(e) => {
            e.stopPropagation();
            document.body.style.cursor = 'pointer'; 
          }}
          onPointerOut={(e) => {
            e.stopPropagation();
            document.body.style.cursor = 'auto'; 
          }} ref={imageMesh} position={[0, 0, -3]}>
        <circleGeometry args={[2, 32]} />
        <meshStandardMaterial map={texture} color="white" transparent side={THREE.DoubleSide} />
      </mesh>
      <Text
        onPointerOver={(e) => {
            e.stopPropagation();
            document.body.style.cursor = 'pointer'; 
          }}
          onPointerOut={(e) => {
            e.stopPropagation();
            document.body.style.cursor = 'auto'; 
          }}
      onClick={handleClick}
      fontSize={.1}
      color="white"
      position={[0, -3.7, 0]}
      textAlign="center"
      anchorX="center"
      anchorY="middle"
    >
      @yusufipsum
    </Text>
    </>
  );
}

export default RivetAnimation;
