import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import SpaceScene from './SpaceScene.js';

function App() {
  return (
    <div style={{ height: '100vh', backgroundColor: '#000', color: '#fff', textAlign: 'center' }}>
      <Canvas>
        <OrbitControls enableZoom={false} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <SpaceScene />
      </Canvas>
    </div>
  );
}

export default App;
