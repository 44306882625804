import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

function Lightning({ start, end, color = 'pink' }) {
  const lineRef = useRef();
  const pulseRef = useRef(0);

  useFrame(() => {
    pulseRef.current += 0.01;
    if (pulseRef.current > 1 * Math.PI) pulseRef.current -= 2 * Math.PI;
    lineRef.current.material.opacity = 0.02 + Math.sin(pulseRef.current) * 0.1;
  });

  const points = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(...start), new THREE.Vector3(...end)]);

  return (
    <line ref={lineRef} geometry={points}>
      <lineBasicMaterial attach="material" color={color} linewidth={1} transparent />
    </line>
  );
}

function Star({ position, color = 'pink', size = 0.05 }) {
  return (
    <mesh position={position}>
      <sphereGeometry args={[size, 32, 32]} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
}

const nisaConstellation = {
  N: [
    [-5, -2, -50],
    [-5, 0, -50],
    [-5, 2, -50],
    [-4.2, 1, -50],
    [-3.5,-.2, -50],
    [-2.8, -1.6, -50],
    [-2.5, .4, -50],
    [-2.2, 2, -50],
  ],
  I: [
    [-.9, 2, -50],
    [-1.1, .8, -50],
    [-1.2, -.5, -50],
    [-1.4, -2, -50],
    
  ],
  S: [
    [2, 1.6, -50],
    [1.2, 2, -50],
    [.4, 1.4, -50],
   
    [1.2, 0, -50],
    [2, -1.2, -50],
 
    [.8, -2, -50],
    [-.1, -1.2, -50],
  ],
  A: [
    [3.2, -2, -50],
    [5, 2, -50],
    [5.6, -2, -50],
  ],
  M: [
    [3.9, -.2, -50],
    // [4.7, -.2, -50],
    [5.4, -.2, -50],
  ],
  Y: [
    [9.2, -2, -50],
    [8.2, -1.2, -50],
    [7, 0, -50],
    [6.6, 1, -50],
    [7.4, 1.8, -50],
    [8.5, 1.5, -50],
    [9.2, 0, -50],
    [9.9, 1.5, -50],
    [11, 1.8, -50],
    [11.8, 1, -50],
    [11.4, 0, -50],
    [10.4, -1.2, -50],
    [9.2, -2, -50],
  ],
};

function NisaConstellation() {
  return (
    <>
      {Object.values(nisaConstellation).flat().map((pos, index) => (
        <Star key={index} position={pos} />
      ))}

      {Object.entries(nisaConstellation).map(([letter, positions]) =>
        positions.map((start, index) => {
          const end = positions[index + 1];
          if (!end) return null;
          return <Lightning key={`${letter}-${index}`} start={start} end={end} />;
        })
      )}
    </>
  );
}

export default NisaConstellation;
